import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';
//import FinnacPortfolioDetail from '../pages/landings/FinaccPortfolioItem/index';
const BlogPost = React.lazy(() => import('../pages/other/BlogPost'));

// finacc
const FinaccLanding = React.lazy(() => import('../pages/landings/Finacc'));
const FinaccPortfolio = React.lazy(() => import('../pages/landings/FinaccPortfolio/index'));
// const FinnacPortfolioDetail = React.lazy(() => import('../pages/landings/FinaccPortfolioItem/index'));
const FinaccAbout = React.lazy(() => import('../pages/landings/FinaccAbout/index'));
const FinaccContact = React.lazy(() => import('../pages/landings/FinaccContact/index'));
const FinaccTeam = React.lazy(() => import('../pages/landings/FinnacTeam'));
const FinnacPortfolioDetail1 = React.lazy(() => import('../pages/landings/FinaccPortfolioItem/Item1'));
const FinnacPortfolioDetail2 = React.lazy(() => import('../pages/landings/FinaccPortfolioItem/Item2'));
const FinnacPortfolioDetail3 = React.lazy(() => import('../pages/landings/FinaccPortfolioItem/Item3'));
const FinnacPortfolioDetail4 = React.lazy(() => import('../pages/landings/FinaccPortfolioItem/Item4'));
const FinnacPortfolioDetail5 = React.lazy(() => import('../pages/landings/FinaccPortfolioItem/Item5'));
const FinnacPortfolioDetail6 = React.lazy(() => import('../pages/landings/FinaccPortfolioItem/Item6'));
const FinaccInsight2 = React.lazy(() => import('./../pages/landings/FinaccInsights/index'));
const FinaccInsight3 = React.lazy(() => import('../pages/landings/FinaccInsights/insights3'));
const FinaccInsight4 = React.lazy(() => import('../pages/landings/FinaccInsights/insights4'));
const FinaccInsight5 = React.lazy(() => import('../pages/landings/FinaccInsights/insights5'));
const WapiPayIMTGetsLicensed = React.lazy(() => import('../pages/landings/FinaccInsights/wapipayInsights'));
const RemittancesCosts = React.lazy(() => import('../pages/landings/FinaccInsights/remittanceCosts'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'contact',
                    element: <LoadComponent component={FinaccContact} />,
                },
                {
                    path: 'remittances-costs',
                    element: <LoadComponent component={RemittancesCosts} />,
                },
                {
                    path: 'wapipay-imt-gets-licensed',
                    element: <LoadComponent component={WapiPayIMTGetsLicensed} />,
                },
                {
                    path: 'insights',
                    element: <LoadComponent component={BlogPost} />,
                },
                {
                    path: 'insight-2',
                    element: <LoadComponent component={FinaccInsight2} />,
                },
                {
                    path: 'insight-3',
                    element: <LoadComponent component={FinaccInsight3} />,
                },
                {
                    path: 'insight-4',
                    element: <LoadComponent component={FinaccInsight4} />,
                },
                {
                    path: 'insight-5',
                    element: <LoadComponent component={FinaccInsight5} />,
                },
                {
                    path: 'team',
                    element: <LoadComponent component={FinaccTeam} />,
                },
                {
                    path: 'about',
                    element: <LoadComponent component={FinaccAbout} />,
                },
                {
                    path: 'services',
                    element: <LoadComponent component={FinaccPortfolio} />,
                },
                {
                    path: 'service-detail-1',
                    element: <LoadComponent component={FinnacPortfolioDetail1} />,
                },
                {
                    path: 'service-detail-2',
                    element: <LoadComponent component={FinnacPortfolioDetail2} />,
                },
                {
                    path: 'service-detail-3',
                    element: <LoadComponent component={FinnacPortfolioDetail3} />,
                },
                {
                    path: 'service-detail-4',
                    element: <LoadComponent component={FinnacPortfolioDetail4} />,
                },
                {
                    path: 'service-detail-5',
                    element: <LoadComponent component={FinnacPortfolioDetail5} />,
                },
                {
                    path: 'service-detail-6',
                    element: <LoadComponent component={FinnacPortfolioDetail6} />,
                },
                {
                    path: 'home',
                    element: <LoadComponent component={FinaccLanding} />,
                },
            ],
        },

    ]);
};

export default AllRoutes;
